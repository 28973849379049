const publicRoutes = {
  accountCreate: "/Onboarding/create_account",
  magicLinkOpen: "/MagicLink/open",
  transactionContextLoad: "/TransactionContext/load",
  transactionContextSubmit: "/TransactionContext/submit",
  transactionContextCreateTemporaryLinkForUpload:
    "/TransactionContext/create_temporary_link_for_upload",
  transactionContextGetTemporaryFileLink:
    "/TransactionContext/get_file_temporary_link",
  transactionContextBreezewayGetOpenTasksForProperty:
    "/TransactionContext/get_breezeway_open_tasks_for_property", // stage and dev returns fake data, production real data
  transactionContextBreezewayCreateTask:
    "/TransactionContext/create_breezeway_task", // stage and dev returns fake data, production real data
  transactionContextGetTrackWorkOrders:
    "/TransactionContext/get_track_work_orders",
  transactionContextGetStreamlineWorkOrders:
    "/TransactionContext/get_streamline_work_orders",
  transactionContextCreateStreamlineWorkOrder:
    "/TransactionContext/create_streamline_work_order",
  transactionContextGetStreamlineConfig:
    "/TransactionContext/get_streamline_config",
  transactionContextGetHostawayCategories:
    "/TransactionContext/get_hostaway_categories",
  transactionContextGetRutterClasses: "/TransactionContext/get_rutter_classes",
  transactionContextGetRutterDepartments:
    "/TransactionContext/get_rutter_departments",
  transactionContextGetRutterAccounts:
    "/TransactionContext/get_rutter_accounts",
};

export const publicRouteObject = (() => {
  const ret: Record<string, string> = {};
  Object.values(publicRoutes).forEach((route) => {
    ret[route] = route;
  });
  return ret;
})();

export const routePaths = {
  dashboard: "/dashboard",
  recipient: "/recipient",
  recipientInvite: "/recipient/invite",
  login: "/login",
  signup: "/signup",
  home: "/",
  pay: "/money-movement/pay",
  transfer: "/money-movement/transfer",
  linkedAccounts: "/accounts/linked",
  ownerRezCallback: "/integrations/ownerrez/callback",
  settingsOwnerRezCallback: "/pms/ownerrez/callback",
  plaidCallback: "/integrations/plaid/callback",
  rampCallback: "/integrations/ramp/callback",
  amazonBusinessCallback: "/integrations/amazonbusiness/callback",
  unauthorized: "/401",
  links: (uuid = "uuid") => `/links/${uuid}`,
};

const privateRoutes = {
  accountFetch: "/Account/fetch",
  accountLimitsFetch: "/Account/fetch_limits",
  accountGenerateStripeAccountLink: "/Account/generate_stripe_account_link",
  accountUpdate: "/Account/update",
  accountUpdateRequirements: "/Account/update_requirements",
  accountCheckStripeRequirementsStatus:
    "/Account/check_stripe_requirements_status",
  amazonBusinessConfigurationExchangeTemporaryCode:
    "/AmazonBusinessConfiguration/exchange_temporary_code",
  amazonBusinessConfigurationFetch: "/AmazonBusinessConfiguration/fetch",
  amazonBusinessConfigurationGetAuthenticationUrl:
    "/AmazonBusinessConfiguration/get_authentication_url",
  cardGenerateStripeEphemeralKey: "/Card/generate_stripe_ephemeral_key",
  cardList: "/Card/list",
  cardCreateWithStripe: "/Card/create_with_stripe",
  cardCreateVirtual: "/Card/_create_virtual", // blocked
  cardCreatePhysical: "/Card/_create_physical", // blocked
  cardUpdate: "/Card/update",
  cardSetTemporaryLimit: "/Card/set_temporary_limit",
  cardTransactionDisputeCreate: "/CardTransaction/create_dispute",
  creditViewGetMonthlyActivity: "/CreditView/get_monthly_activity",
  dashboardViewGetBalanceGraphData: "/DashboardView/get_balance_graph_data",
  dashboardViewGetThisMonthTransactionsAggregates:
    "/DashboardView/get_this_month_transactions_aggregates",
  fileCreateTemporaryLinkForUpload: "File/create_temporary_link_for_upload",
  fileGetTempLink: "/File/get_temporary_link",
  integrationCreate: "/Integration/create",
  integrationCreateMany: "/Integration/create_many",
  integrationList: "/Integration/list",
  integrationUpdate: "/Integration/update",
  integrationDelete: "/Integration/delete",
  metaTransactionList: "/MetaTransaction/list",
  metaTransactionGetTotals: "/MetaTransaction/get_totals",
  ExportList: "/Export/list",
  ExportPmsList: "/Export/pms_list",
  ExportAttachmentZip: "/Export/generate_attachment_zip",
  metaTransactionUpdate: "/MetaTransaction/update",
  mercoaGenerateToken: "/Mercoa/generate_token",
  mercoaSetup: "/Mercoa/setup",
  mercoaSetupSubaccounts: "/Subaccount/mercoa_sync_all",
  mercoaSetupRecipients: "/Recipient/mercoa_sync_all",
  mercoaSetupUsers: "/User/mercoa_sync_all",
  mercoaUploadOwnerRezPayouts: "/Mercoa/upload_ownerrez_payouts",
  mercoaUploadAchFile: "/Mercoa/upload_ach_file",
  ownerRezConfigurationFetchUserAuthorizationUrl:
    "/OwnerRezConfiguration/fetch_user_authorization_url",
  ownerRezConfigurationExchangeTemporaryCode:
    "/OwnerRezConfiguration/exchange_temporary_code",
  ownerRezConfigurationRevokeAccess: "/OwnerRezConfiguration/revoke",
  ownerRezGetAllCategories: "OwnerRez/get_categories",
  paymentCreateAch: "/Payment/create_outbound_ach",
  paymentCreateWire: "/Payment/create_outbound_wire",
  paymentCreateOutboundAchRecipient:
    "/Payment/create_outbound_ach_for_recipient",
  paymentCreateOutboundWireRecipient:
    "/Payment/create_outbound_wire_for_recipient",
  paymentCreateCheckbookPayment: "/Payment/create_checkbook_payment",
  paymentCreateCheckbookPaymentRecipient:
    "/Payment/create_checkbook_payment_for_recipient",
  plaidGetLinkToken: "/Plaid/get_link_token",
  plaidGetUpdateLinkToken: "/Plaid/get_update_link_token",
  plaidExchangePublicToken: "/Plaid/exchange_public_token",
  plaidRefreshItemStatus: "/Plaid/refresh_item_status",
  pmsIntegrationImportProperties: "/PmsIntegration/import_properties",
  pmsSyncStart: "/PmsSync/start",
  pmsSyncList: "/PmsSync/list",
  pmsTransactionListSyncable: "/PmsTransaction/list_syncable",
  pmsTransactionUpdateSyncStatus: "/PmsTransaction/update_sync_status",
  pmsTransactionUpdateSyncDetails: "/PmsTransaction/update_sync_details",
  pmsTransactionCountReadyForSync: "/PmsTransaction/count_ready_for_sync",
  pmsTransactionCountNotYetSynced: "/PmsTransaction/count_not_yet_synced",
  propertyCreateWithStripeCard: "/Property/create_with_stripe_card",
  propertyCreateWithValidation: "/Property/create_with_validation",
  propertyList: "/Property/list",
  propertyUpdate: "/Property/update",
  propertyViewList: "/PropertyView/list",
  rampExchangeTokens: "/Ramp/exchange_tokens",
  rampGetAuthenticationUrl: "/Ramp/get_authentication_url",
  recipientList: "/Recipient/list",
  recipientDelete: "/Recipient/delete",
  recipientCreateForBankTransfer: "/Recipient/create_for_bank_transfer",
  recipientCreateForCheck: "/Recipient/create_for_check",
  recipientInvite: "/Recipient/invite",
  recipientUpdateForBankTransfer: "/Recipient/update_for_bank_transfer",
  recipientUpdateForCheck: "/Recipient/update_for_check",
  recipientUpdateDetails: "/Recipient/update_recipient_details",
  recipientPaginatedList: "/Recipient/paginated_list",
  rulesEngineList: "/RulesEngine/list",
  rulesEngineCreate: "/RulesEngine/create",
  rulesEngineUpdate: "/RulesEngine/update",
  rulesEngineDelete: "/RulesEngine/delete",
  rutterAccountList: "/RutterAccount/list",
  rutterAccountSubsidariesList: "/RutterAccount/list_subsidiaries",
  rutterClassList: "/RutterClass/list",
  rutterDepartmentList: "/RutterDepartment/list",
  rutterConnectionFetch: "/RutterConnection/fetch",
  rutterConnectionUpdate: "/RutterConnection/update",
  rutterConnectionFetchStatus: "/RutterConnection/fetch_status",
  rutterConnectionDisconnect: "/RutterConnection/disconnect",
  rutterConnectionCreateWithPublicToken:
    "/RutterConnection/create_with_public_token",
  rutterJobResult: "/RutterJob/get_job_result",
  rutterSyncStart: "/RutterSync/start",
  rutterSyncList: "/RutterSync/list",
  rutterTransactionCountReadyForSync: "/RutterTransaction/count_ready_for_sync",
  rutterTransactionCountNotYetSynced: "/RutterTransaction/count_not_yet_synced",
  rutterVendorCreate: "/RutterVendor/create",
  rutterVendorList: "/RutterVendor/list",
  rutterCustomerList: "/RutterCustomer/list",
  rutterInvoiceList: "/RutterInvoice/list",
  statementList: "/Statement/list",
  stripeCreditAccountFetch: "/StripeCreditAccount/fetch",
  stripeCreditApplicationGenerateOnboardingLink:
    "/StripeCreditApplication/generate_onboarding_link",
  stripeCreditApplicationSubmitStep: "/StripeCreditApplication/submit_step",
  stripeCreditApplicationComplete: "/StripeCreditApplication/complete",
  stripeCreditObligationFetchTotals: "/StripeCreditObligation/fetch_totals",
  stripeCreditObligationFetchCurrent: "/StripeCreditObligation/fetch_current",
  stripeCreditObligationPaymentCreate: "/StripeCreditObligationPayment/create",
  subaccountList: "/Subaccount/list",
  subaccountUpdate: "/Subaccount/update",
  subaccountRutterSync: "/Subaccount/rutter_sync",
  subaccountCreateRutterAccount: "/Subaccount/create_rutter_account",
  getRutterAccountName: "/Subaccount/get_rutter_account_name",
  guestyGetAllVendors: "/Guesty/get_all_vendors",
  guestyGetVendorsById: "/Guesty/get_vendors_by_id",
  guestyCreateConfig: "/GuestyConfiguration/create_and_validate",
  guestyFetchConfig: "/GuestyConfiguration/fetch",
  guestyUpdateConfig: "/GuestyConfiguration/update",
  hostawayGetAllCategories: "/Hostaway/get_all_categories",
  hostawayCreateConfig: "/HostawayConfiguration/create_and_validate",
  trackGetAllWorkOrders: "/Track/get_all_work_orders",
  trackGetAllVendors: "/Track/get_all_vendors",
  trackGetVendorsById: "/Track/get_vendors_by_id",
  trackGetAccounts: "/Track/get_accounts",
  trackCreateConfig: "/TrackConfiguration/create_and_validate",
  trackFetchConfig: "/TrackConfiguration/fetch",
  trackUpdateConfig: "/TrackConfiguration/update",
  breezewayCreateConfig: "/BreezewayConfiguration/create_and_validate",
  breezewayFetchConfig: "/BreezewayConfiguration/fetch",
  breezewayUpdateConfig: "/BreezewayConfiguration/update",
  breezewayGetOpenTasks: "/Breezeway/get_open_tasks_for_property",
  breezewayCreateTask: "/Breezeway/create_task",
  streamlineCreateConfig: "/StreamlineConfiguration/create_and_validate",
  streamlineFetchConfig: "/StreamlineConfiguration/fetch",
  streamlineUpdateConfig: "/StreamlineConfiguration/update",
  streamlineGetAllWorkOrders: "/Streamline/get_all_work_orders",
  streamlineCreateWorkOrder: "/Streamline/create_work_order",
  transferCreateInbound: "/Transfer/create_inbound",
  transferCreateOutbound: "/Transfer/create_outbound",
  transferCreateOutboundToSubaccount: "/Transfer/create_outbound_to_subaccount",
  transferUpsertCheckDeposit: "/CheckDeposit/upsert",
  checkDepositCheckUpload: "/CheckDeposit/create_file",
  checkDepositFetch: "/CheckDeposit/fetch_with_uuid",
  checkDepositConfirm: "/CheckDeposit/confirm",
  checkPaymentSetup: "/Checkbook/setup",
  lineItemCreateWithMetaTransactionId:
    "/LineItem/create_with_meta_transaction_id",
  lineItemDeleteWithMetaTransactionId:
    "/LineItem/delete_with_meta_transaction_id",
  lineItemListWithMetaTransactionId: "/LineItem/list_with_meta_transaction_id",
  lineItemCreateWithTransactionId: "/LineItem/create_with_transaction_id",
  lineItemDeleteWithTransactionId: "/LineItem/delete_with_transaction_id",
  lineItemListWithTransactionId: "/LineItem/list_with_transaction_id",
  lineItemCreateWithCardAuthorizationId:
    "/LineItem/create_with_card_authorization_id",
  lineItemDeleteWithCardAuthorizationId:
    "/LineItem/delete_with_card_authorization_id",
  lineItemListWithCardAuthorizationId:
    "/LineItem/list_with_card_authorization_id",
  metaTransactionFetch: "MetaTransaction/fetch_with_uuid",
  metaTransactionReceiptUpload: "/MetaTransaction/attach_uploaded_file",
  metaTransactionRemind: "/MetaTransaction/remind",
  transfersListInbound: "/Transfer/list_inbound",
  transfersListAll: "/Transfer/list_all",
  user: "/User/loopback",
  userList: "/User/list",
  userFetchWithAuth0UserId: "/User/fetch_with_auth0_user_id",
  userCreate: "/User/create",
  userUpdateInfo: "/User/update",
  userUpdateName: "/User/update_name",
  userUpdateRole: "/User/update_role",
  userChangePassword: "/User/change_password",
  userFetchPermisions: "/User/fetch_permissions",
  userDeactivate: "/User/deactivate",
  userActivate: "/User/activate",
  userResendInvite: "/User/resend_invite",
  linkedAccountList: "/LinkedAccount/list_all",
  linkedAccountCreateOrUpdate: "/LinkedAccount/create_or_update",
  linkedAccountGenerateStripeFinancialConnectionSetupIntent:
    "/LinkedAccount/generate_stripe_financial_connection_setup_intent",
  customFieldList: "/CustomField/list",
  customFieldCreate: "/CustomField/create",
  customFieldUpdate: "/CustomField/update",
  customFieldDelete: "/CustomField/delete",
  buildiumCreateConfig: "/BuildiumConfiguration/create_and_validate",
};

export const routes = {
  ...publicRoutes,
  ...privateRoutes,
};
