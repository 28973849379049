import { useUserContext } from "context/user";
import { api } from "network";
import { routes } from "network/routes";
import { useHfQuery } from "network/useHfQuery";
import { UseQueryOptions } from "react-query";
import {
  Account,
  AccountLimits,
  HostfiUserRoles,
  WelcomeFlowStep,
} from "typings/shared";

export const queryKeyFetchAccount = "fetchAccount";
export const queryKeyFetchAccountLimits = "fetchAccountLimits";
export const fetchAccount = (): Promise<Account> =>
  api.post(routes.accountFetch, {});

export const useFetchAccount = (options?: UseQueryOptions<Account>) => {
  const user = useUserContext();

  return useHfQuery<Account>(queryKeyFetchAccount, routes.accountFetch, {
    options: {
      enabled: typeof user.account_id === "number",
      ...options,
    },
  });
};

export const useFetchAccountLimits = () => {
  return useHfQuery<AccountLimits>(
    queryKeyFetchAccountLimits,
    routes.accountLimitsFetch,
  );
};

export const getNextRequirementPath = (
  remainingRequirements: WelcomeFlowStep[],
  role: HostfiUserRoles,
  currentStep?: WelcomeFlowStep,
) => {
  const reqs = remainingRequirements.filter((req) => req !== currentStep);
  const [nextPathStep] = reqs;
  if (nextPathStep) {
    if (role === "admin" && nextPathStep === "credit_onboarding") {
      return {
        nextPath: "/credit/application",
        remainingRequirements: reqs,
      };
    }

    return {
      nextPath: `/signup/${nextPathStep?.replace(/_/g, "-")}`,
      nextPathStep,
      remainingRequirements: reqs,
    };
  }

  return {
    nextPath: "/dashboard",
    remainingRequirements: reqs,
  };
};

export const useGetNextRequirementPath = (currentStep?: WelcomeFlowStep) => {
  const { data } = useFetchAccount();
  const { role } = useUserContext();

  const remainingRequirements = data?.remaining_requirements || [];
  return getNextRequirementPath(remainingRequirements, role, currentStep);
};
